'use client';

import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import PianoService from './services/PianoService';
import { pianoId } from './services/analyticsIds';

const Analytics = () => {
	const pathname = usePathname();

	const [isPianoInitialized, setIsPianoInitialized] = useState(false);

	// Initialize Piano
	useEffect(() => {
		if (PianoService.isAvailable() && !isPianoInitialized) {
			PianoService.initialize();
			setIsPianoInitialized(true);
		}
	}, [isPianoInitialized, setIsPianoInitialized]);

	// Run on every page change
	useEffect(() => {
		const { name, chapter2, chapter3 } = getAnalyticsPageNameAndChapters(
			pathname ?? '',
		);

		// Send to Piano
		PianoService.sendPage({
			name,
			page_chapter2: chapter2,
			page_chapter3: chapter3,
		});
	}, [pathname]);

	return (
		<>
			<Script
				src="https://tag.aticdn.net/piano-analytics.js"
				type="text/javascript"
				strategy="beforeInteractive"
			/>
			<Script
				type="text/javascript"
				strategy="beforeInteractive"
				id="piano-config"
				dangerouslySetInnerHTML={{
					__html: `window.pa.setConfigurations({
                        site: ${pianoId},
                        collectDomain: 'https://atconnect.npo.nl',
                    });`,
				}}
			/>
			<Script
				src="https://hub.npo-data.nl/tags/tag.min.js"
				strategy="beforeInteractive"
				type="text/javascript"
			/>
		</>
	);
};

const getAnalyticsPageNameAndChapters = (pathname: string) => {
	const pathWithoutFirstSlash = pathname.slice(1);
	const pathParts = pathWithoutFirstSlash.split('/');

	const isIndex = pathname.length <= 1 || pathParts.length === 0;

	const [chapter2, chapter3, ...others] = pathWithoutFirstSlash.split('/');

	const name = isIndex
		? 'index'
		: others.length
			? others.join('/')
			: (chapter3 ?? chapter2 ?? '');

	return {
		pathParts,
		name,
		chapter2:
			`${chapter2}`.length > 0 && chapter2 !== name
				? chapter2
				: undefined,
		chapter3:
			`${chapter3}`.length > 0 && chapter2 !== name
				? chapter3
				: undefined,
	};
};

export const measure = (
	name: string,
	type: 'navigation' | 'action' | 'exit' | 'download' = 'action',
) => {
	const {
		name: pageName,
		chapter2,
		chapter3,
	} = getAnalyticsPageNameAndChapters(window.location.pathname);

	// Send to Piano
	PianoService.sendEvent({
		type: `click.${type}`,
		options: {
			name,
			page: pageName,
			click_chapter2: chapter2,
			click_chapter3: chapter3,
		},
	});
};

export default Analytics;
