// Check if APP_DEBUG is false and NEXT_PUBLIC_URL does not contain 'test' or 'localhost'
export default function isProduction() {
	const env = process?.env ?? {};

	const isDebug = env.APP_DEBUG == 'true';

	const appUrl =
		env.NEXT_PUBLIC_URL ??
		(typeof window !== 'undefined'
			? new URL(window.location.href).origin
			: '');

	return (
		!isDebug && !appUrl.includes('test') && !appUrl.includes('localhost')
	);
}
